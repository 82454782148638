<template>
    <nav class="navbar sticky-top flex-md-nowrap p-0">
        <div class="navbar-brand col-md-3 col-lg-3 col-xl-2 px-4 mr-0">
	        <template v-if="typeof brandLink.internal !== 'undefined'">
		        <router-link :to="brandLink.internal"><span class="navbar-brand-text">{{brandLink.text}}</span></router-link>
	        </template>
	        <template v-else-if="typeof brandLink.to !== 'undefined'">
		        <router-link :to="brandLink.to"><span class="navbar-brand-text">{{brandLink.text}}</span></router-link>
	        </template>
            <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>
        <div class="col-md-9 col-lg-9 ml-sm-0 col-xl-10 px-4 mr-xl-auto">
            <span class="navbar-text mr-auto"><span class="company pl-0">{{viewingEntityName}}</span></span>
            <span class="navbar-text navbar-buttons pl-4 pr-0">
                <span class="username pr-3">{{name}}</span>
	            <small-button v-if="loggedIn" :text="$t('terms.logout')" @click.native="performLogout"  />
	            <router-link v-else :to="{name:'login',params: {lang: $i18n.locale}}" ><small-button :text="$t('terms.login')" /></router-link>
            </span>
        </div>
    </nav>
</template>

<script>
    import SmallButton from "@/components/buttons/SmallButton";
    import {mapState,mapGetters} from "vuex";

    export default {
        name: "Navbar",
        components:{SmallButton},
        computed: {
            ...mapState('concrete', {
				name: 'name',
	            loggedIn: 'loggedIn',
	            districts: 'districts',
	            companies: 'companies',
	            viewingEntity: 'viewingEntity'
            }),
	        ...mapGetters('concrete', {
		        viewingEntityIRIParts: 'viewingEntityIRIParts',
	        }),
	        viewingEntityName(){
            	if(this.viewingEntity === false)
            		return '';
            	
            	const parts = this.viewingEntityIRIParts;
            	if(parts.entity === 'Company' && typeof this.companies[parts.id] !== 'undefined')
            		return this.companies[parts.id].name;
            	else if(parts.entity === 'District' && typeof this.districts[parts.id] !== 'undefined')
		            return this.districts[parts.id].name;
            	
                return '';
	        },
            routeName() {
                return this.$route.name;
            },
            routeParams(){
                return this.$route.params;
            },
	        brandLink(){
            	if(this.loggedIn)
            		return {to:{name:'admin',params:{lang: this.$i18n.locale}},text:this.$t('terms.dashboard')};
            	
                return {
	                internal: '/'+this.$i18n.locale,
	                text: this.$t('terms.dashboard'),
                }
	        }
        },
	    methods: {
		    performLogout(){
		    	this.$store.dispatch('concrete/logout')
			    .then(() => {
			    	this.$router.push({name:'login',params:{lang: this.$i18n.locale}})
			    },() => {
			    	this.$router.push({name:'login',params: {lang: this.$i18n.locale}})
			    })
		    }
	    }
    }
</script>

<style lang="scss" scoped>
    /*
    * Navbar
    */
    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }

    .nav {
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 100;
        background: $sidebar;
    
        .navbar-brand{
            width: 100%;
            background: $navbarBrand;
            color: $white;
            font-size: 1.3125rem;
            font-weight: 700;
            padding: 1.375rem 2.5rem;

        }
        
    }
    .navbar-brand-text {
        color: #FFF;
        font-size: 1.3125rem;
        font-weight: 700;
    }

    .navbar-buttons{
        float: right;
    }

    .navbar{
        background: $white;
        .navbar-brand{
            background: $navbarBrand;
            color: $white;
            font-size: 1.3125rem;
            font-weight: 700;
            padding: 1.375rem 2.5rem;
            //box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
        }
        .navbar-text{
            .company{
                color: $grey;
                font-size: 1.3125rem;
                font-weight: 700;
            }
        }
    }
    .navbar .navbar-toggler {
        top: 1.05rem;
        right: 1rem;
    }
</style>

