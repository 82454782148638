<template>
    <footer class="footer">
        <span class="text-muted">Concrete Elephant V0.2</span>
        <span class="float-right">
            <a class="text-muted" href="#">Help</a>
        </span>
        <span class="float-right pr-3">
            <!--<a class="text-muted" href="#">EN</a> <a class="text-muted" href="#">DA</a>-->
            <select v-model="$i18n.locale" @change='updateLanguage'>
                <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
            </select>
        </span>
    </footer>
</template>

<script>
    export default {
        name: "PageFooter",
        data () {
            return { langs: ['da', 'en','de'] }
        },
        methods: {
            updateLanguage(){
				this.$concreteApi.put(this.$store.state.concrete['apiKey'],'/api/users/'+this.$store.state.concrete['user'],{
                    locale: this.$i18n.locale
                },true).then((result) => {
                }).catch(error => {
                    console.log(error);
                    if(error.status){
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .footer {
        position: absolute;
        bottom: 0;
        width: calc(100% - 3rem);
        height: 80px;
        line-height: 60px;

    }
    .help {
        float: right;
    }

    @media screen and (max-width: 767px){
        .footer {
            position: relative;
            width: calc(100%);
        }
    }
</style>
