<template>
    <nav id="sidebarMenu" class="col-md-3 col-lg-3 col-xl-2 d-md-block sidebar collapse">
        <div class="sidebar-sticky pt-3">
	        <!--<ul v-if="viewingEntity !== false && globalScreenPermissions.length > 0" class="nav flex-column">
		        <li class="nav-item">
			        <a class="nav-link back-button"  href="#" @click="leaveEntityView">Leave {{viewingEntityName}} view</a>
		        </li>
	        </ul>-->
	        <slot></slot>
	        <!--
            &lt;!&ndash; sub headline (maybe) &ndash;&gt;
            <h6 class="sidebar-heading">Saved reports</h6>


            &lt;!&ndash; basic ul &ndash;&gt;
            <ul class="nav flex-column">
                <li class="nav-item">
                    <a class="nav-link active" href="#">Billing details</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Employees</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Bookings</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Invoices</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Locations</a>
                </li>
            </ul>

            &lt;!&ndash; sub headline (maybe) &ndash;&gt;
            <h6 class="sidebar-heading">Saved reports</h6>

            &lt;!&ndash; basic ul &ndash;&gt;
            <ul class="nav flex-column">
                <li class="nav-item">
                    <a class="nav-link" href="#">
                        <span data-feather="file-text"></span>
                        Current month
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">
                        <span data-feather="file-text"></span>
                        Last quarter
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">
                        <span data-feather="file-text"></span>
                        Social engagement
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">
                        <span data-feather="file-text"></span>
                        Year-end sale
                    </a>
                </li>
            </ul>
-->
        </div>
    </nav>
</template>

<script>
	import {mapGetters, mapState} from "vuex";

    export default {
        name: "Sidebar",
	    computed: {
		    ...mapState('concrete', {
			    viewingEntity: 'viewingEntity',
		    }),
		    ...mapGetters('concrete',{
			    viewingEntityIRIParts: 'viewingEntityIRIParts',
			    globalScreenPermissions: 'globalScreenPermissions'
		    }),
		    viewingEntityName(){
		    	return this.viewingEntityIRIParts.entity.toLowerCase();
		    }
	    },
	    methods: {
		    leaveEntityView(){
		    	this.$store.commit('concrete/setViewingEntity',false);
		    	this.$router.push({name:'admin',params:{lang:this.$i18n.locale}});
		    }
	    }
    }
</script>

<style lang="scss" scoped>
	.sidebar {
        /*position: fixed;*/
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 100;
        /*padding: 75px 0 0;*/
        background: $sidebar;
        .nav-link {
            font-weight: 400;
            color: $white;
            padding: 0.5rem 1.5rem;
            &.active {
                font-weight: 700;
            }
        }
        .sidebar-heading {
            color: $white;
            font-size: .75rem;
            text-transform: uppercase;
            padding: 0.5rem 1.5rem;
            margin-top: 1.5rem;
            opacity: 0.5;
            font-weight: 700;
        }
    }

    @media (max-width: 767.98px) {
        .sidebar {
            top: 5rem;
        }
    }

    .sidebar-sticky {
        position: relative;
        top: 75px;
        height: calc(100vh - 75px); /* height: calc(100vh - 48px);*/
        padding-top: .5rem;
        overflow-x: hidden;
        overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    }

    @supports ((position: -webkit-sticky) or (position: sticky)) {
        .sidebar-sticky {
            position: -webkit-sticky;
            position: sticky;
        }
    }

    @media screen and (max-width: 767px){
        .sidebar{
            position: fixed;
            z-index: 1050;
            top: 4.7rem
        }
    }


    .back-button{
		position: relative;
		&:before {
			content: "";
			background-image: url("~@/assets/images/back-arrow.svg");
			width: 20px;
			height: 20px;
			display: inline-block;
			background-size: contain;
			/* background-position: 50% 60%; */
			vertical-align: middle;
			position: absolute;
			left: 0px;
			top: 8px;
		}
	}
</style>
